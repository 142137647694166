import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'
import { deckOrPorch } from '../utils/formSchema/constants'

export const uiSchema: UiSchema = {
    blocking_type: {
        'ui:placeholder': 'Select Type',
    },
    dimension: {
        'ui:placeholder': 'Select Dimension',
    },
    beam_cut: {
        'ui:placeholder': 'Select Beam Cut',
    },
    ijoist_brand: {
        'ui:placeholder': 'Select Brand',
    },
    series: {
        'ui:placeholder': 'Select Series',
    },
    ijoist_size: {
        'ui:placeholder': 'Select I-Joist Size',
    },
    pitch: {
        'ui:placeholder': 'Select Pitch',
    },
    pitch_other: {
        'ui:title': 'Pitch Other',
        'ui:placeholder': 'Enter Pitch',
    },
    plies: {
        'ui:title': 'Plies',
        'ui:placeholder': 'Select Plies',
    },
    is_treated: {
        'ui:title': 'Treated',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    ewp_type: {
        'ui:title': 'EWP Type',
        'ui:placeholder': 'Select EWP Type',
    },
    glulam_type: {
        'ui:title': 'Glulam Type',
        'ui:placeholder': 'Select Glulam Type',
    },
    width: {
        'ui:title': 'Width',
        'ui:placeholder': 'Select Width',
    },
    width_other: {
        'ui:title': 'Other Width',
        'ui:placeholder': 'Enter Width in inches',
    },
    height: {
        'ui:title': 'Height',
        'ui:placeholder': 'Select Height',
    },
    height_other: {
        'ui:title': 'Height Other',
        'ui:placeholder': 'Enter Height in inches',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Bundle Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Bundle Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Bundle Other Floor Level',
    },
    'ui:order': [
        'blocking_type',
        'plies',
        'dimension',
        'ijoist_brand',
        'series',
        'ijoist_size',
        'pitch',
        'pitch_other',
        'isTreated',
        'ewp_type',
        'glulam_type',
        'height',
        'height_other',
        'width',
        'width_other',
        'beam_cut',
    ],
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const labelOther = 'other'
    let newSettings = { ...settings, ...formData }

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }
    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }
    if (newSettings.height === 'other') {
        newSettings.height = newSettings.height_other
    }
    if (newSettings.width === 'other') {
        newSettings.width = newSettings.width_other
    }

    switch (newSettings.blocking_type) {
        case 'DIMENSIONAL LUMBER':
            newSettings = {
                ...newSettings,
                beam_cut: null,
                height: null,
                height_other: null,
                width: null,
                width_other: null,
                ewp_type: null,
                glulam_type: null,
                blocking_ijoist_brand: null,
                series: null,
                ijoist_brand: null,
                ijoist_size: null,
                pitch: null,
                pitch_other: null,
            }
            break
        case 'SOLID BEAM':
            newSettings = {
                ...newSettings,
                plies: null,
                dimension: null,
                ewp_type: null,
                glulam_type: null,
                blocking_ijoist_brand: null,
                series: null,
                ijoist_brand: null,
                ijoist_size: null,
                pitch: null,
                pitch_other: null,
                width_other: null,
                height_other: null,
            }
            break
        case 'EWP':
            newSettings = {
                ...newSettings,
                dimension: null,
                advanced_settings: null,
                beam_cut: null,
                blocking_ijoist_brand: null,
                series: null,
                ijoist_brand: null,
                ijoist_size: null,
                pitch: null,
                pitch_other: null,
            }
            break
        case 'I-JOIST':
            newSettings = {
                ...newSettings,
                dimension: null,
                advanced_settings: null,
                beam_cut: null,
                height: null,
                height_other: null,
                width: null,
                width_other: null,
                ewp_type: null,
                glulam_type: null,
            }
            break
        default:
    }

    if (formData.pitch === labelOther) {
        newSettings.pitch = formData.pitch_other
    }

    newSettings.pitch_other = null

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
